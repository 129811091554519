<template>

    <table class="mt-10 w-full">
        <thead>
            <tr>
                <th width="32%">Product</th>
                <th width="12%">{{ totalFormulaDosage() }}</th>
                <th width="32%">Replacement Product</th>
                <th width="12%">Dosage</th>
                <th width="12%" class="text-right">Actions</th>
            </tr>
        </thead>
        <tbody v-if="(formula && !((hasSubmittedNewItem)&&(isAddingReplacement)))">
          <!-- Loading Spinner While `hasSubmitted` == true -->
          <tr v-if="hasSubmittedNewItem || hasSubmittedReplacementItem">
            <td><the-spinner></the-spinner></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <!-- Rows of Formula Items -->
          <template v-if="formula.length > 0 && !(hasSubmittedNewItem || hasSubmittedReplacementItem)">
            <tr v-for="(item, index) in baseProducts" :key="index"
                :class="{'unfocus-row': (fields.scriptFormula.new1.fields.replaces != item.id && fields.scriptFormula.new1.fields.replaces != null)}">
                <td>
                  <!-- @TODO change `outOfStock` value on click -->
                  <a @click="toggleProductStatus(item)" class="product-status">
                    <i v-if="!item.outOfStock" class="fas fa-check-circle icon-instock"></i>
                    <i v-if="item.outOfStock" class="far fa-circle icon-outofstock"></i>
                  </a>
                  <a :href="item.drug.url" :class="{'out-of-stock': item.outOfStock}">{{ item.drug.title }}</a>
                </td>
                <td :class="{'out-of-stock': item.outOfStock}">
                  {{ `${item.dosage ? item.dosage.toFixed(3) : 'N/A'} ${item.uom}` }}
                </td>
                <!-- replacement product -->
                <td>
                  <template v-for="replacement in replacementProducts">
                    <p v-if="(replacement.group === item.group)"
                      :key="`replacement-product-${replacement.id}`"
                      class="replacement-data-in-table"
                    >
                      <a :href="replacement.drug.url" :class="{'replacement-inactive': replacement.outOfStock}">
                        {{ replacement.drug.title }}
                      </a>
                    </p>
                  </template>
                </td>
                <!-- replacement dosage -->
                <td>
                  <template v-for="replacement in replacementProducts">
                    <p v-if="(replacement.group === item.group)"
                      :key="`replacement-dosage-${replacement.id}`"
                      class="replacement-data-in-table"
                      :class="{'replacement-inactive': replacement.outOfStock}"
                    >
                      {{ replacement.dosage }} {{ replacement.uom }}
                    </p>
                  </template>
                </td>
                <td class="text-right">
                  <button v-show="!isAddingReplacement" @click="openReplacementForm(item.id, item.sortOrder)" class="font-medium text-steel-500 text-sm uppercase hover:underline">REPLACE</button>
                  <button v-show="isAddingReplacement" @click="cancelReplacement" class="font-medium text-steel-500 text-sm uppercase hover:underline">Cancel</button>
                </td>
            </tr>
          </template>
          <!-- Replacement Form -->
          <tr v-show="isAddingReplacement" class="add-replacement">
              <td>
                  <entry-field input-id="replacementDrugInput" section-handle="drugs" @relatedEntrySelected="replacementDrugSelected"></entry-field>
              </td>
              <td colspan="2">
                <input v-model="fields.scriptFormula.new1.fields.dosage" placeholder="Dosage" class="mr-3">
                <select v-model="fields.scriptFormula.new1.fields.uom">
                    <option selected value="CC">CC</option>
                    <option value="Gallon(s)">Gallon(s)</option>
                </select>
              </td>
              <td colspan="2" class="text-right">
                  <button @click="cancelReplacement" class="font-medium text-steel-600 text-xs uppercase hover:underline mr-4">Cancel</button>
                  <button @click="submitReplacement" class="button bg-steel-500 hover:bg-steel-600 font-bold text-white text-xs uppercase">
                    Add Replacement
                  </button>
              </td>
          </tr>
          <!-- Add New Formula Item Row -->
          <script-formula-form
            v-show="!isAddingReplacement"
            :has-submitted-new-item="hasSubmittedNewItem"
            :new-item-saved="newItemSaved"
            :script-id="scriptId"
            @itemSubmitted="submitNewItem"
          ></script-formula-form>
        </tbody>
        <tbody v-else-if="this.formula === ''">
          <tr>
            <td>No Formula</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>
              <the-spinner></the-spinner>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
    </table>

</template>

<style scoped>
  .icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
  .replacement::before {
    position: absolute;
    top: -7px;
    content: '\f064';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    @apply text-steel-100;
  }
  .replacementRow {
    @apply border-b-2 border-steel-200;
  }
  .replaced {
    @apply bg-cloud-100;
  }
  a.product-status i { @apply text-sm cursor-pointer px-2; }
  a.product-status i:hover { @apply text-steel-600; }
</style>

<script>
import ScriptFormulaForm from './ScriptFormulaForm'
import { handleError } from "../../modules/handleError";
export default {
  name: 'ScriptFormula',

  components: { ScriptFormulaForm },

  data() {
    return {
      hasSubmittedNewItem: false,
      hasSubmittedReplacementItem: false,
      isAddingReplacement: false,
      newItemSaved: false,
      currentFormulaItems: null,
      fields: {
        scriptFormula: {
          new1: {
            type: 'formulaItem',
            enabled: 1,
            sortOrder: null,
            fields: {
              drug: null,
              dosage: null,
              uom: 'CC',
              replaces: null,
              outOfStock: 0,
              replacedBy: null,
              group: null
            }
          }
        },
      },
    }
  },

  computed: {
    formula()   { return this.$store.getters.FORMULA; },
    scriptId()  { return this.$store.getters.SCRIPT_ID; },

    /**
     * Computed Formula
     *
     * - Rename formula() to baseFormula()
     * - Rename computedFormula() to formula()
     * - Loop over baseProducts()
     * - Then for each baseProduct loop over replacementProducts()
     * - For each replacement product that matches base product's group (that isn't already part of `replacedBy`):
     *   Push its (the replacement product) id to a temp array, then turn the array
     *       into a comma separated list using `String(tempArray)` and assign this
     *       to `baseProducts[i].replacedBy`.
     * - Then we'll have to save it to Craft. But this seems silly to request, modify, save, request...
     */
    computedFormula() {},

    // the original Script products, these are the products that will have replacements, if any.
    baseProducts() {
      if (this.formula && this.formula.length > 0) {
        return this.formula.filter(baseProduct => !baseProduct.replaces);
      }
    },
    /**
     * returning replacementProducts[0][n] but it should be replacementProducts[n]
     */
    replacementProducts() {
      if (this.formula && this.formula.length > 0) {
        return this.formula.filter(replacement => replacement.replaces);
      }
      // if (this.formula && this.formula.length > 0) {
      //   let replacementProducts = this.formula
      //     .filter(item => {
      //       return Object.keys(item.replacements) > 0;
      //     })
      //     .map( (item, i) => {
      //       Object.keys(item.replacements).map(replacement => {
      //         console.log(replacement);
      //       })
      //     });
      //   return replacementProducts;
      // }
    },
  },

  methods: {
    openReplacementForm(replacingId, replacingSortOrder) {
      this.isAddingReplacement = true;
      // Clear any old replacement data
      this.clearReplacementValues();
      // Wait a tick and then change focus to the replacement drug field
      let drugFieldFocusTimeout;
      let drugField = document.getElementById('replacementDrugInput');
      drugFieldFocusTimeout = window.setTimeout( function() {
        drugField.focus();
      }, 300);
      // Assign ID of Formula Item we are making a replacement for to the replacement field.
      this.fields.scriptFormula.new1.fields.replaces = replacingId;
      // @todo Craft does not respect the sortOrder we give it. Modify it via before_save_event?
      this.fields.scriptFormula.new1.sortOrder = Number(replacingSortOrder) + 1;
    },

    replacementDrugSelected(id) {
      this.fields.scriptFormula.new1.fields.drug = [id];
      this.fields.scriptFormula.new1.fields.group = this.fields.scriptFormula.new1.fields.replaces;
    },

    clearReplacementValues() {
      this.fields.scriptFormula.new1.sortOrder = null;
      this.fields.scriptFormula.new1.fields.drug = null;
      this.fields.scriptFormula.new1.fields.dosage = null;
      this.fields.scriptFormula.new1.fields.unit = 'CC';
      this.fields.scriptFormula.new1.fields.replaces = null;
      this.fields.scriptFormula.new1.fields.outOfStock = 0;
      this.fields.scriptFormula.new1.fields.replacedBy = null;
    },

    cancelReplacement() {
      this.clearReplacementValues();
      this.isAddingReplacement = false;
    },

    /**
     * Add All Formula Blocks To Formula Submission
     */
    addAllFormulaBlocksToFormulaSubmission() {
      for (let i = 0; i < this.formula.length; i++) {
        // Formula Block that is being replaced by a new replacement
        if (this.fields.scriptFormula.new1.fields.replaces == this.formula[i].id) {
          this.$set(
            this.fields.scriptFormula,
            this.formula[i].id,
            {
              type: 'formulaItem',
              enabled: 1,
              fields: {
                drug: [this.formula[i].drug.id],
                dosage: this.formula[i].dosage,
                uom: this.formula[i].uom,
                replaces: '',
                replacedBy: '',
                group: this.formula[i].id,
                outOfStock: 1,
              }
            }
          );
        } else {
          this.$set(
            this.fields.scriptFormula,
            this.formula[i].id,
            {
              type: 'formulaItem',
              enabled: 1,
              fields: {
                drug: [this.formula[i].drug.id],
                dosage: this.formula[i].dosage,
                uom: this.formula[i].uom,
                replaces: this.formula[i].replaces,
                replacedBy: '',
                group: this.formula[i].group,
                outOfStock: this.formula[i].outOfStock == true || this.formula[i].outOfStock == 1 ? 1 : 0,
              }
            }
          );
        }
      }
    },

    /**
     * Submit Replacement
     *
     */
    submitReplacement() {
      this.hasSubmittedReplacementItem = true;

      // make sure we're sending all pre-exisitng Formula Blocks with our submission
      this.addAllFormulaBlocksToFormulaSubmission();

      // what we're sending
      let data = {
        entryId: this.scriptId,
        fields: this.fields
      };
      console.log('About to submit: ', data);
      this.$axios.post('actions/entries/save-entry', data)
          .then(response => {
            console.log('Formula saved.');
            console.log(response);
            // query Formula again and update Store?
            this.fetchUpdatedFormula(this.scriptId);
          })
          .catch(handleError);
      // clear replacement data and turn off `isAddingReplacement`.
      // this.isAddingReplacement = false;
    },

    fetchUpdatedFormula(scriptId) {
      let data = { "scriptId": scriptId };
      this.$axios.post('actions/vetscripts/scripts/formula', data)
        .then((response) => {
          this.$store.commit('SET_FORMULA', response.data.formula);
          this.clearReplacementValues();
          this.hasSubmittedNewItem = false;
          this.hasSubmittedReplacementItem = false;
          this.isAddingReplacement = false;
        })
        .catch(handleError);
    },

    submitNewItem(data) {
      this.hasSubmittedNewItem = true;
      this.newItemSaved = false;
      this.$axios.post('actions/entries/save-entry', data)
        .then((response) => {
          console.log(response);
          this.fetchUpdatedFormula(this.scriptId);
          if (response.data.success === true) {
            this.lotsSaved = response.data.success;
          }
          if (response.data.errors) {
            this.errors = response.data.errors;
          }
          this.hasSubmitted = false;
        })
        .catch(handleError);
    },

    totalFormulaDosage() {
      if ( (this.baseProducts && this.baseProducts.length > 0) ) {
        let totalDosage = 0;
        for (let i = 0; i < this.baseProducts.length; i++) {
          for (let r = 0; r < this.replacementProducts.length; r++) {
            if (this.replacementProducts[r].group !== this.baseProducts[i].group) continue;
            if (!this.baseProducts[i].outOfStock) continue;
            totalDosage += this.replacementProducts[r].dosage * 100;
          }
          if (this.baseProducts[i].outOfStock) continue;
          totalDosage += this.baseProducts[i].dosage * 100;
        }
        const total = totalDosage / 100;
        return `${total.toFixed(3)} ${this.formula[0].uom}`;
      }
    },

    /**
     * Toggle Product Status
     *
     * When the user clicks on the status icon next to the main product this method will
     * be called and will toggle its `isOutOfStock` value as well as any products that
     * are replacing it.
     * @todo can we optimize the double loop below?
     * @param product Object
     */
    toggleProductStatus(product) {
      this.hasSubmittedNewItem = true;
      this.addAllFormulaBlocksToFormulaSubmission();
      const tempFormula = JSON.parse(JSON.stringify(this.fields.scriptFormula));
      delete tempFormula['new1'];
      const keys = Object.keys(tempFormula);

      // test keys against clicked product and then check for matching replacements.
      keys.forEach(function(key) {
        if (key == product.id) {
          product.outOfStock == true
            ? tempFormula[key].fields.outOfStock = 0
            : tempFormula[key].fields.outOfStock = 1;
          for (let i of Object.keys(tempFormula)) {
            if (tempFormula[i]['fields']['replaces'] == product.id) {
              // if the base product is out of stock, we set the replacement product
              // to out of stock also, because we are checking against the base product's
              // old out of stock value from Craft, not the intention of the user and the ui
              product.outOfStock == true
                ? tempFormula[i]['fields']['outOfStock'] = 1
                : tempFormula[i]['fields']['outOfStock'] = 0;
            }
          }
        }
      });

      const data = {
        entryId: this.scriptId,
        fields: {
          scriptFormula: tempFormula
        }
      };

      this.$axios.post('actions/entries/save-entry', data)
        .then(response => {
          console.log('Formula updated.');
          this.fetchUpdatedFormula(this.scriptId);
        })
        .catch(handleError);
    },

  }
}
</script>
