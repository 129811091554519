<template>
    <tr class="border-t-2 border-cloud-100">
      <td>
        <input
          v-model="fields.scriptLots.new1.fields.lotNumber"
          class="input"
          type="text"
          placeholder="Lot Number"
        >
      </td>
      <td>
        <input
          v-model="fields.scriptLots.new1.fields.lotMixDate.date"
          class="input"
          type="text"
          placeholder="10/31/2020"
        >
      </td>
      <td>
        <input
          v-model="fields.scriptLots.new1.fields.lotExpireDate.date"
          class="input"
          type="text"
          placeholder="10/31/2021"
        >
      </td>
      <td>
        <label v-if="sites">
          <select v-model="fields.scriptLots.new1.fields.lotSite">
            <option value="">No Site</option>
            <option
              v-for="site in sites"
              :value="[site.id]"
              :key="site.id"
            >{{site.title}}</option>
          </select>
        </label>
      </td>
      <td class="text-center">
        <button
          @click="lotSubmitted"
          :disabled="hasSubmitted"
          class="button bg-cloud-300 font-bold text-steel-400 text-xs uppercase"
        >Add Lot</button>
      </td>
    </tr>
</template>

<script>
export default {
  name: 'ScriptLotForm',

  props: {
    hasSubmitted: Boolean,
    lots: Array,
    lotsSaved: Boolean,
    scriptId: Number,
  },

  computed: {
    script() { return this.$store.getters.SCRIPT; },
    sites() { return this.$store.getters.SCRIPT_SITES; }
  },

  data () {
    return {
      entryId: this.scriptId,
      sectionId: 3,
      enabled: 1,
      fields: {
        scriptLots: {
          new1: {
            type: 'lot',
            enabled: 1,
            fields: {
              lotNumber: '',
              lotMixDate: {
                date: '',
                timezone: 'America/Chicago',
              },
              lotExpireDate: {
                date: '',
                timezone: 'America/Chicago'
              },
              lotSite: ''
            }
          }
        }
      }
    }
  },

  watch: {
    lotsSaved: function(newVal) {
      if (newVal === true) {
        this.fields.scriptLots.new1.fields.lotNumber = '';
        this.fields.scriptLots.new1.fields.lotMixDate = {date:'', timezone:'America/Chicago'};
        this.fields.scriptLots.new1.fields.lotExpireDate = {date:'', timezone:'America/Chicago'};
        this.fields.scriptLots.new1.fields.lotSite = '';
      }
    }
  },

  methods: {
    addAllLotsToScriptLots () {
      let count = this.lots.length;
      for (let i = 0; i < count; i++) {
        this.$set(
          this.fields.scriptLots,
          this.lots[i].id,
          {
            // we only need to include the type and whether it is enabled
            type: 'lot',
            enabled: 1,
          }
        );
      }
    },

    lotSubmitted () {
      // make sure we're sending all pre-existing Lots along with our submission
      this.addAllLotsToScriptLots();
      // what we're sending
      let data = {
        entryId: this.entryId,
        sectionId: this.sectionId,
        enabled: this.enabled,
        fields: this.fields
      };
      // bird's away!
      this.$emit('lotSubmitted', data);
    }
  }
}
</script>
