<template>
    <div v-if="script" class="flex px-4" style="width:59%">
        <!-- dark side -->
        <div class="flex flex-col justify-around bg-cloud-400 w-36 print:w-auto p-8 rounded-l text-center shadow">
            <p class="text-5xl">{{ script.title }}</p>
            <div v-if="script.replaces.length > 0" class="my-4">
                <p class="font-medium text-sm text-steel-400 uppercase">Replaces</p>
                <a v-for="(replacedScript, index) in script.replaces"
                   :key="index"
                   class="text-steel-600 hover:underline text-xl"
                   :class="{ 'text-3xl': replacesOneScript(script.replaces.length) }"
                   :href="replacedScript.url">
                    {{ printReplacedScript(replacedScript, index) }}
                </a>
            </div>
            <i class="far fa-prescription text-7xl text-steel-200"></i>
        </div>
        <!-- script info -->
        <div class="bg-white flex-grow leading-tight rounded-r shadow">
            <div class="flex flex-col justify-between min-h-full">
                <div class="flex-grow p-8">
                    <!-- client, diagnosis, vet, agent, and dates -->
                    <ul class="mb-4 text-steel-500">
                        <li>
                            <a class="text-steel-600 text-xl hover:underline" :href="script.client.url">
                                {{ script.client.title }}
                            </a>
                        </li>
                        <li class="text-xl text-steel-600">
                            {{ script.diagnosis }}
                        </li>
                        <li v-for="(vet, index) in script.veterinarian" :key="index">
                            <a class="text-steel-500 hover:underline" :href="vet.url">{{ vet.title }}</a>
                            <span class="text-sm"> ({{ vet.license }})</span>
                        </li>
                        <li>
                            {{ script.agent }}
                            {{ script.postDate.date|formatDate }} {{script.postDate.date|formatTime}}
                        </li>
                    </ul>
                    <!-- sites, species/class, route, sw, and container -->
                    <ul class="text-steel-500 text-sm">
                        <li v-if="script.sourceFlow">
                            {{ script.sourceFlow }}
                        </li>
                        <li v-if="script.sites.length > 0">
                            <a v-for="(site, index) in script.sites" :key="index" class="text-steel-600 hover:underline" :href="site.url">
                                {{ printScriptSites(site, index) }}
                            </a>
                        </li>
                        <li v-if="script.species || script.productionClass" class="inline">
                            {{ script.species }} / {{ script.productionClass }}
                        </li>
                        <li v-if="script.administrativeRoute" class="inline">
                            | {{ script.administrativeRoute.label }} Route
                        </li>
                        <li v-if="script.slaughterWithdrawal" class="inline">
                            | {{ printSlaughterWithdrawal(script.slaughterWithdrawal) }}
                        </li>
                        <li v-if="script.containerSize" class="inline">
                            | {{ printContainerAndRefillable(script.containerSize.value, script.refillable.value) }}
                        </li>
                        <li v-if="script.labelColor && script.labelColor !== 'noColor'" class="inline capitalize">
                          | {{ labelColor }}
                        </li>
                    </ul>
                    <!-- Special/Extra Instructions -->
                    <div class="mt-4 text-sm">
                        <p v-if="script.specialInstructions" >
                            <strong>Special Script Instructions:</strong>
                            {{ script.specialInstructions }}
                            <br><br>
                        </p>
                        <p v-if="script.extraDosageInstructions">
                            <strong>Extra Dosage Instructions:</strong>
                            {{ script.extraDosageInstructions }}
                            <br><br>
                        </p>
                    </div>
                </div>
                <!-- Interaction -->
                <div class="p-4 border-t-2 border-cloud-100">
                    <div class="flex justify-end">
                        <a :href="cpEditUrl" target="new" class="font-medium text-steel-600 text-sm uppercase hover:underline mr-4">Edit</a>
                        <a :href="printScriptPrintUrl(script.id)" target="_blank" class="font-medium text-steel-600 text-sm uppercase hover:underline cursor-pointer">Print</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="flex px-4" style="width:59%">
      <div class="flex flex-col justify-around bg-cloud-400 w-36 p-8 rounded-l shadow"></div>
      <div class="bg-white flex-grow text-center leading-tight p-8 rounded-r shadow">
        <the-spinner></the-spinner>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ScriptInfo',

  computed: {
    script() {
      return this.$store.getters.SCRIPT;
    },
    cpEditUrl() {
      return this.$store.getters.CP_EDIT_URL;
    },
    labelColor() {
      return `${this.script.labelColor} Label`;
    }
  },

  methods: {
    printReplacedScript(script, index) {
      if (index + 1 === this.script.replaces.length) {
        return script.title;
      } else {
        return script.title + ', ';
      }
    },
    replacesOneScript(length) {
      if (length === 1) return true;
    },
    printScriptSites(site, index) {
      if (index + 1 === this.script.sites.length) {
        return site.title;
      } else {
        return site.title + ', ';
      }
    },
    printSlaughterWithdrawal(sw) {
      if (sw > 1) {
        return sw + ' Days SW';
      } else {
        return sw + ' Day SW';
      }
    },
    printContainerAndRefillable(containerSize, refillable) {
      if (refillable === 'Refillable') {
        return containerSize + ' CC Refillable Container';
      } else {
        return containerSize + ' CC Container';
      }
    },
    printScriptPrintUrl(scriptId) {
      return window.Craft.baseUrl + '/prints/script?scriptId=' + scriptId;
    },
  }

}
</script>
