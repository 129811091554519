<template>
    <div class="fluid-container">
        <!-- Script Info and Label Preview -->
        <header class="flex -mx-4">
            <script-info></script-info>
            <script-label></script-label>
        </header>

        <!-- Script Formula -->
        <script-formula></script-formula>

        <!-- Script Lots -->
        <script-lots v-if="!script.specialOralDrug"></script-lots>
    </div>
</template>

<script>
import { handleError } from "../modules/handleError";
export default {
  name: 'TheScript',

  props: {
    scriptId: Number,
    isEditable: Number,
    cpEditUrl: String
  },

  data () {
    return {
      script: '',
      lots: ''
    }
  },

  created () {
    this.commitScriptId();
    this.commitIsEditable();
    this.commitCpEditUrl();
    this.getScript();
    this.getLots();
  },

  methods: {
    commitIsEditable () {
      this.$store.commit('SET_IS_EDITABLE', this.isEditable);
    },
    commitScriptId () {
      this.$store.commit('SET_SCRIPT_ID', this.scriptId);
    },
    commitCpEditUrl() {
      this.$store.commit('SET_CP_EDIT_URL', this.cpEditUrl);
    },
    getScript () {
      let data = { 'scriptId': this.scriptId };
      this.$axios.post('actions/vetscripts/scripts/script', data)
        .then((response) => {
          console.log('The Script',response);
          this.$store.commit('SET_FORMULA', response.data.formula);
          this.$store.commit('SET_SCRIPT', response.data.script);
          this.$store.commit('SET_REPLACEMENT_DRUGS', response.data.replacementDrugs);
          this.$store.commit('SET_SCRIPT_SITES', response.data.script.sites);
        })
        .catch(handleError);
    },
    /**
     * Get Lots
     * @todo what would be more performant: get two arrays from the controller or do the recent Lots logic here?
     * Requests Lot data from the Scripts Controller. Should receive two arrays:
     * One containing all Lots related to the provided Script ID and the second
     * array should contain the 5 most recent related Lots.
     */
    getLots () {
      let data = { scriptId: this.scriptId };
      this.$axios.post('actions/vetscripts/scripts/lots', data)
        .then((response) => {
          console.log('Script Lots', response);
          this.$store.commit('SET_SCRIPT_LOTS', response.data.lots);
          this.$store.commit('SET_SCRIPT_RECENT_LOTS', response.data.recentLots);
        })
        .catch(handleError);
    },
  },
}
</script>
