let formatDate = function(value, locale = 'en-US') {
  return value
    ? new Date(value).toLocaleDateString(locale)
    : ''
}

// TODO: Return the time without trailing seconds.
let formatTime = function(value, locale = 'en-US') {
  return value
  ? new Date(value).toLocaleTimeString(locale, {hour: '2-digit', minute:'2-digit'})
  : ''
}

let formatDateTime = function(value, locale = 'en-US') {
  return value
  ? new Date(value).toLocaleString(locale)
  : ''
}

export { formatDate, formatDateTime, formatTime }