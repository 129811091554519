import Axios from 'axios'
import Vue from 'vue'
import store from './store'
import { stringify } from 'qs'
import Components from './components'
import './app.scss'
import { getAppVersion } from "./modules/appVersion";
import { formatDate, formatTime, formatDateTime } from "./modules/formatDateAndTime";
getAppVersion();

// Axios setup --------------------------------------------
Axios.defaults.baseURL = Craft.baseUrl;
Axios.defaults.headers.common['Accept'] = 'application/json';
Axios.defaults.headers.common['X-CSRF-Token'] = window.Craft.csrfTokenValue;
Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const AxiosInstance = Axios.create({
  transformRequest: [
    function(data) {
      return stringify(data)
    }
  ]
});


// Vue setup --------------------------------------------
Vue.config.productionTip = false;
Vue.prototype.$axios = AxiosInstance;
Vue.filter('formatDate', formatDate);
Vue.filter('formatTime', formatTime);
Vue.filter('formatDateTime', formatDateTime);

new Vue({
  store,
  el: '#app',
  delimiters: ['${', '}'],
  data: { sitePicked: '' }
});


/**
 * Lot Table
 *
 * This script automates the width of all drug columns
 * in the lot table that gets printed on the back-side
 * of all scripts.
 */

let $table           = $('#lot_table_on_back');
let $columns         = $('.lot-table-drug-column');
let $mix_date_column = $('.lot_table_mix_date');
let $lot_column      = $('.lot_table_lot_number');
let $exp_date_column = $('.lot_table_exp_date');


$(window).on("load resize", function(e){

  // Get width of table on window load and resize
  let $table_width = $table.width();

  // Get widths of "fixed" columns and assign total to $fixed_columns_w
  let $mix_date_w      = $mix_date_column.width();
  let $lot_w           = $lot_column.width();
  let $exp_date_w      = $lot_column.width();
  let $fixed_columns_w = $mix_date_w + $lot_w + $exp_date_w;

  // Get number of drug columns and determine equal size for each,
  // assign this to $drug_column_w
  let $drug_column_count = $columns.length;
  let $drug_column_w     = ( $table_width - $fixed_columns_w ) / $drug_column_count;

  // assign the equal size to each drug column.
  // $columns.width( $drug_column_w );
  $columns.css( "min-width", $drug_column_w + "px !important" );

});


/**
 * Table Sort
 */
jQuery(document).ready(function($){
  $('.is-sortable').tablesorter();
});


/**
 * Detach lot site dropdown
 */
jQuery(document).ready(function($){
  $( '#detach_site_dropdown' ).click( function() {
    $( '#site_dropdown_field' ).detach();
  });
});


/**
 * Print targeted section of a page
 */

let printScriptBtn = document.getElementById('printScriptBtn');
let printScript = document.getElementById('printScript');
if (printScriptBtn) {
  printScriptBtn.addEventListener('click', function() {
    printScript.classList.remove('hidden');
    printScript.classList.add('block');
    window.print();
    printScript.classList.remove('block');
    printScript.classList.add('hidden');
  });
}

let printLabelBtn = document.getElementById('printLabelBtn');
let printLabel = document.getElementById('printLabel');
if (printLabelBtn) {
  printLabelBtn.addEventListener('click', function() {
    printLabel.classList.remove('hidden');
    printLabel.classList.add('block');
    window.print();
    printLabel.classList.remove('block');
    printLabel.classList.add('hidden');
  });
}

let printOrderBtn = document.getElementById('printOrderBtn');
let printOrder = document.getElementById('printOrder');
if (printOrderBtn) {
  printOrderBtn.addEventListener('click', function() {
    printOrder.classList.remove('hidden');
    printOrder.classList.add('block');
    window.print();
    printOrder.classList.remove('block');
    printOrder.classList.add('hidden');
  });
}