<template>
  <table class="lots-table mt-10 w-full">
    <thead>
      <tr>
        <th>Last 5 Lots</th>
        <th>Mix Date</th>
        <th>Expiration Date</th>
        <th>Site</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="lots">
      <script-lot-form
        v-if="isEditable"
        :has-submitted="hasSubmitted"
        :script-id="scriptId"
        :lots="this.lots"
        :lots-saved="this.lotsSaved"
        :script-sites="this.scriptSites"
        @lotSubmitted="submitLot">
      </script-lot-form>
        <tr v-if="errors">
          <td>
            <p v-for="(error,index) in errors" :key="index">
              {{ error[0] }}
            </p>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-if="hasSubmitted">
          <td>
            <the-spinner></the-spinner>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-for="lot in recentLots" :key="lot.id">
          <td>{{lot.lotNumber}}</td>
          <!-- Mix Date -->
          <td v-if="lot.lotMixDate">
            {{ lot.lotMixDate.date|formatDate }}
          </td>
          <td v-else></td>
          <!-- Expire Date -->
          <td v-if="lot.lotExpireDate">
            {{ lot.lotExpireDate.date | formatDate }}
          </td>
          <td v-else></td>
          <!-- Site -->
          <td v-if="lot.lotSite.length > 0">
            {{ lot.lotSite[0].title }}
          </td>
          <td v-else></td>
          <!-- blank cells below "Add Lot" button -->
          <td></td>
        </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td>
          <the-spinner></the-spinner>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ScriptLotForm from './ScriptLotForm'
import { handleError } from "../modules/handleError";
export default {
  name: 'ScriptLots',

  components: {
    ScriptLotForm
  },

  computed: {
    scriptId() { return this.$store.getters.SCRIPT_ID; },
    lots() { return this.$store.getters.LOTS; },
    recentLots() { return this.$store.getters.RECENT_LOTS; },
    sites() { return this.$store.getters.SCRIPT_SITES; },
    isEditable() { return this.$store.getters.IS_EDITABLE; },
  },

  data: function () {
    return {
      errors: null,
      hasSubmitted: false,
      lotsSaved: false,
      scriptSites: [],
      test: ''
    }
  },

  methods: {
    getLots () {
      let data = { scriptId: this.scriptId };
      this.$axios.post('actions/vetscripts/scripts/lots', data)
        .then((response) => {
          this.$store.commit('SET_SCRIPT_LOTS', response.data.lots);
          this.$store.commit('SET_SCRIPT_RECENT_LOTS', response.data.recentLots);
          this.hasSubmitted = false;
        })
        .catch(handleError);
    },

    submitLot (data) {
      this.hasSubmitted = true;
      this.errors = null;
      this.lotsSaved = false;
      this.$axios.post('actions/entries/save-entry', data)
        .then((response) => {
          if (response.data.success === true) {
            this.lotsSaved = response.data.success;
          }
          if (response.data.errors) {
            this.errors = response.data.errors;
          }
          this.getLots();
        })
        .catch(handleError);
    }
  }
}
</script>
