<template>
  <div>
    <div class="flex justify-between">
      <h1 class="inline-block text-4xl text-steel-600">
        New Order
      </h1>
      <button @click="saveEntry()" class="button bg-mint text-mint-light" :disabled="isSaving">
        Save Order
      </button>
    </div>

    <div v-if="isSaving" class="flex bg-white p-8 mt-4 rounded shadow">
      <the-spinner></the-spinner>
    </div>

    <!-- Continue along if we aren't actively trying to save something... -->
    <div v-else class="bg-white p-8 mt-4 rounded shadow">

      <div v-if="orderUrl" class="bg-steel-400 border-t-4 border-steel-600 rounded-b mb-12 mt-2 px-6 py-6 shadow" role="alert">
        <div class="flex">
          <div class="py-1 pr-6">
            <i class="fas fa-thumbs-up text-5xl text-mint-light"></i>
          </div>
          <div>
            <p class="font-bold pb-1 text-lg text-white">Order saved successfully!</p>
            <p class="text-steel-100">Redirecting you to your new Order.</p>
            <p class="text-steel-100">If you haven't been redirected after a few seconds, <a :href="orderUrl" class="text-white underline">click here</a> to go to your Order.</p>
          </div>
        </div>
      </div>

      <!-- Client and Script and option -->
      <div class="flex mb-4">
        <div class="w-1/4">
          <label for="title">Title</label>
          <input v-model="title" id="title" type="text" placeholder="Order Name"/>
        </div>
        <div class="w-1/4">
          <label for="newClientInput">Client</label>
          <entry-field
            input-id="newClientInput"
            section-handle="clients"
            placeholder="Client Name"
            @relatedEntrySelected="clientSelected"
          ></entry-field>
        </div>
        <div class="w-1/4">
          <label for="newScriptInput">Script</label>
          <entry-field
            input-id="newScriptInput"
            section-handle="scripts"
            placeholder="Script Number"
            @relatedEntrySelected="scriptSelected"
          ></entry-field>
        </div>
        <div class="w-1/4">
          <label for="dosage">Dosage</label>
          <input v-model="fields.orderDosage" id="dosage" type="number" step="0.25"/>
        </div>
      </div>

      <!-- Filters -->
      <div class="w-1/3 mb-8 mt-8">
        <button
          @click="selectPickup()"
          class="button transition rounded-r-none focus:outline-none select-none"
          :class="{
            'bg-cloud-100 hover:bg-cloud-200 text-steel-300': !this.selected.pickup,
            'bg-steel-300 hover:bg-steel-400 text-white': this.selected.pickup
          }"
        >Pickup</button>
        <button
          @click="selectShipping()"
          class="button transition rounded-none focus:outline-none select-none"
          :class="{
            'bg-cloud-100 hover:bg-cloud-200 text-steel-300': !this.selected.shipping,
            'bg-steel-300 hover:bg-steel-400 text-white': this.selected.shipping
          }"
        >Shipping</button>
        <button
          @click="selectSendWithVet()"
          class="button transition rounded-l-none focus:outline-none select-none"
          :class="{
            'bg-cloud-100 hover:bg-cloud-200 text-steel-300': !this.selected.sendWithVet,
            'bg-steel-300 hover:bg-steel-400 text-white': this.selected.sendWithVet
          }"
        >Send With Vet</button>
      </div>

      <!-- Pickup fields -->
      <div v-if="selected.pickup">
        <div class="flex items-center mb-2">
          <label class="w-24" for="pickUpTime">Pickup Date</label>
          <input v-model="fields.pickUpTime.datetime" type="datetime-local" id="pickUpTime"/>
        </div>
        <div class="flex items-center">
          <label class="w-24" for="nightbox">Nightbox?</label>
          <input v-model="fields.nightbox" type="checkbox" id="nightbox"/>
        </div>
      </div>

      <!-- Shipping fields -->
      <div v-if="selected.shipping">
        <div class="flex items-center mb-2">
          <label for="shippingCarrier" class="w-32">Shipping Carrier</label>
          <select v-model="fields.shippingCarrier" id="shippingCarrier" class="appearance-none p-2 w-24">
            <option value="speedee">Speedee</option>
            <option value="ups">UPS</option>
          </select>
        </div>
        <div class="flex items-center mb-2">
          <label for="shippingDate" class="w-32">Shipping Date</label>
          <input v-model="fields.shippingDate.date" type="date" id="shippingDate"/>
        </div>
        <div class="flex items-center mb-2">
          <label for="shippingTo" class="w-32">Shipping To</label>
          <input v-model="fields.shipTo" type="text" id="shippingTo"/>
        </div>
      </div>

      <!-- Send With Vet field -->
      <div v-if="selected.sendWithVet" class="flex items-center">
        <label for="sendWithVet">Send With Vet</label>
        <input v-model="fields.sendWithVet.datetime" type="datetime-local" id="sendWithVet"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  @apply inline-block text-base mr-4 select-none
}
</style>

<script>
import {handleError} from '../../../modules/handleError';
import TheSpinner from '../../TheSpinner';
import EntryField from '../../EntryField';
export default {
  name: 'NewOrder',
  data() {
    return {
      isSaving: false,
      title: '',
      selected: {
        pickup: true,
        shipping: false,
        sendWithVet: false,
      },
      fields: {
        client: [],
        script: [],
        orderDosage: '',
        pickUpTime: {
          datetime: '',
          timezone: 'America/Chicago',
        },
        nightbox: 0,
        shippingCarrier: '',
        shippingDate: {
          date: '',
          timezone: 'America/Chicago',
        },
        shipTo: '',
        sendWithVet: {
          datetime: '',
          timezone: 'America/Chicago'
        }
      },
      orderUrl: ''
    }
  },
  methods: {
    saveEntry() {
      this.isSaving = true;
      let data = {
        title: this.title,
        fields: this.fields,
      };
      this.$axios.post('actions/vetscripts/orders/save', data)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.afterSaveCleanup();
            this.orderUrl = response.data.entryUrl;
            window.location.href = this.orderUrl;
          }
          if (response.data.errors) {
            console.log(errors);
          }
        })
        .catch(handleError);
    },
    afterSaveCleanup() {
      this.title = '';
      this.isSaving = false;
      this.fields.client = [];
      this.fields.script = [];
      this.fields.pickUpTime.datetime = '';
      this.fields.nightbox = 0;
      this.fields.shippingCarrier = '';
      this.fields.shippingDate.date = '';
      this.fields.shipTo = '';
      this.fields.sendWithVet.datetime = '';
    },
    newDrugSelected(id) {
      this.fields.scriptFormula.new1.fields.drug = [id];
      this.fields.scriptFormula.new1.fields.group = id;
    },
    clientSelected(clientId) {
      this.fields.client = [clientId];
    },
    scriptSelected(scriptId) {
      this.fields.script = [scriptId];
    },
    selectPickup() {
      this.selected.pickup = !this.selected.pickup;
      // Turn off any other filters
      this.selected.shipping = false;
      this.selected.sendWithVet = false;
      // Empty any other filled fields
      this.fields.shippingCarrier = '';
      this.fields.shippingDate.date = '';
      this.fields.shipTo = '';
      this.fields.sendWithVet.datetime = '';
    },
    selectShipping() {
      this.selected.shipping = !this.selected.shipping;
      // Turn off any other filters
      this.selected.pickup = false;
      this.selected.sendWithVet = false;
      // Empty any other filled fields
      this.fields.pickUpTime.datetime = '';
      this.fields.nightbox = 0;
      this.fields.sendWithVet.datetime = '';
    },
    selectSendWithVet() {
      this.selected.sendWithVet = !this.selected.sendWithVet;
      // Turn off any other filters
      this.selected.pickup = false;
      this.selected.shipping = false;
      // Empty any other filled fields
      this.fields.pickUpTime.datetime = '';
      this.fields.nightbox = 0;
      this.fields.shippingCarrier = '';
      this.fields.shippingDate.date = '';
      this.fields.shipTo = '';
    }
  }
}
</script>
