<template>
    <tr>
        <td><input placeholder="Drug Title"></td>
        <td><input placeholder="Dosage"></td>
        <td><input placeholder="UOM"></td>
        <td class="text-right">
            <button @click="saveReplacement" class="font-medium text-steel-500 text-sm uppercase hover:underline">SAVE</button>
        </td>
    </tr>
</template>

<script>
export default {
  name: 'ScriptFormulaReplacement',

  methods: {
    saveReplacement() {
      console.log('Look at you go!');
    }
  }
}
</script>