<template>
    <div class="inline relative">
        <!-- @todo fill the value of this input with the entry title when one is selected, not just the search value -->
        <input
          v-model="searchValue"
          @input="startSearch"
          @keyup.enter="startSearch"
          @keyup.esc="close"
          :id="inputId"
          :placeholder="placeholder ? placeholder : 'Entry Title'">
        <!-- Results -->
        <div v-if="isSearching || results" class="results z-10">

            <!-- spinner if we're waiting for search results -->
            <div v-if="isSearching" class="flex items-center p-4">
                <the-spinner></the-spinner>
                <span class="ml-4 text-2xl">Searching...</span>
            </div>

            <!-- the results once the search returns results! -->
            <div v-if="results">
                <div v-for="entry in results" @click="entrySelected(entry.id, entry.title)" class="entries-list-item cursor-pointer">
                    <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.title"></Highlighter>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .results {
        @apply absolute bg-white rounded-b shadow-md mt-3 text-steel-500;
        width: 650px;
    }
    .results a { @apply text-steel-500 }
    .entries-list-item { @apply py-4 px-6; }
    .entries-list-item:not(:last-child) { @apply border-b-2 border-cloud-100; }
    .entries-list-item:hover { @apply bg-cloud-100; }
</style>

<script>
import Highlighter from 'vue-highlight-words'
import { debounce } from 'lodash'
export default {
  name: 'EntryField',
  components: { Highlighter },
  props: {
    inputId: String,
    sectionHandle: String,
    placeholder: String,
    relatedToSection: String,
    relatedToEntryId: Array|Number|String
  },

  data() {
    return {
      searchValue: null,
      selectedEntryTitle: null,
      results: null,
      isSearching: false
    }
  },

  computed: {
    keywords() {
      if (this.searchValue === null) return [];
      return this.searchValue.split(' ');
    }
  },

  methods: {

    close() { this.results = '' },

    startSearch() {
      if (this.searchValue.length < 3) return;
      this.isSearching = true;
      this.search();
    },

    search: debounce( function() {
      let data = {
        'relatedToEntryId': this.relatedToEntryId ? this.relatedToEntryId : null,
        'searchValue': this.searchValue,
        'sectionHandle': this.sectionHandle
      };
      this.$axios.post('actions/vetscripts/search/entry-field', data)
          .then((response) => {
            this.isSearching = false;
            this.results = response.data.results;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            }
            console.log(error.config);
          })
    }, 500),

    entrySelected(id, title) {
      this.$emit('relatedEntrySelected', id);
      this.searchValue = title;
      this.results = null;
    }
  }
}
</script>
