<template>
  <div class="relative">
    <input v-model="searchValue" @input="startSearch" @keyup.enter="startSearch" @keyup.esc="close" id="topBarSearchField" type="search" name="q" placeholder="Search Vet Scripts..." class="w-full">

    <!-- Results -->
    <div v-if="isSearching || results" class="results z-10">

      <div v-if="isSearching" class="flex items-center p-4">
        <the-spinner></the-spinner>
        <span class="ml-4 text-2xl">Searching...</span>
      </div>

      <div v-if="Object.keys(results).length > 0">
        <!-- Section Group -->
        <div v-for="(section, sectionTitle) in results" @click="select(result.id)" :key="sectionTitle">
          <p class="section-group-header">{{ sectionTitle }}</p>
          <!-- Entries in group -->
          <div v-if="sectionTitle != 'scripts'" class="entries-list">
            <div v-for="(entry, index) in section" :key="index" class="">
              <a :href="entry.url">
                <Highlighter class="highlighter entries-list-item block w-full" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.title"></Highlighter>
              </a>
            </div>
          </div>
          <!-- More info if the entries are Scripts -->
          <div v-else class="entries-list">
            <div v-for="(entry, index) in section" :key="index" class="entries-list-item flex">
              <div class="flex-no-grow pr-6">
                <a :href="entry.url">
                  <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.title"></Highlighter>
                </a>
              </div>
              <div class="flex flex-wrap text-sm">
                <div v-if="entry.client" class="pr-6 pb-2">
                  <span class="text-steel-400">Client: </span>
                  <a :href="entry.title">
                    <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.client"></Highlighter>
                  </a>
                </div>
                <div v-if="entry.primaryVet" class="pr-6">
                  <span class="text-steel-400">P. Vet: </span>
                  <a :href="entry.title">
                    <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.primaryVet"></Highlighter>
                  </a>
                </div>
                <div v-if="entry.diagnosis" class="pr-6">
                  <span class="text-steel-400">Diagnosis: </span>
                  <a :href="entry.title">
                    <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.diagnosis"></Highlighter>
                  </a>
                </div>
                <div v-if="entry.primaryDrug" class="pr-6">
                  <span class="text-steel-400">P. Drug: </span>
                  <a :href="entry.title">
                    <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.primaryDrug"></Highlighter>
                  </a>
                </div>
                <div v-if="entry.primarySite" class="pr-6">
                  <span class="text-steel-400">P. Site: </span>
                  <a :href="entry.title">
                    <Highlighter class="highlighter" highlightClassName="autocomplete-highlight" :searchWords="keywords" :autoEscape="true" :textToHighlight="entry.primarySite"></Highlighter>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Section Filter -->
        <div class="flex justify-center py-3 border-t-2 border-cloud-100">
          <div @click="clickClientsFilter" class="section-filter-btn">
            <span v-html="searchSection(sections.clients)"></span> Clients
          </div>
          <div @click="clickDrugsFilter" class="section-filter-btn">
            <span v-html="searchSection(sections.drugs)"></span> Drugs
          </div>
          <div @click="clickOrdersFilter" class="section-filter-btn">
            <span v-html="searchSection(sections.orders)"></span> Orders
          </div>
          <div @click="clickScriptsFilter" class="section-filter-btn">
            <span v-html="searchSection(sections.scripts)"></span> Scripts
          </div>
          <div @click="clickSitesFilter" class="section-filter-btn">
            <span v-html="searchSection(sections.sites)"></span> Sites
          </div>
          <div @click="clickVeterinariansFilter" class="section-filter-btn">
            <span v-html="searchSection(sections.veterinarians)"></span> Veterinarians
          </div>
        </div>
        <!-- More Options -->
        <div class="bg-cloud-200 flex justify-between py-3 px-8">
          <button @click="close" class="section-filter-btn hover:bg-cloud-300 text-steel-500">Close</button>
          <div>
            <button disabled class="section-filter-btn hover:bg-cloud-200 cursor-not-allowed">Advanced Search</button>
            <a :href="allResultsUrl()" class="section-filter-btn hover:bg-cloud-300 text-steel-500">Get All Results</a>
          </div>
        </div>
      </div>
      <div v-if="noResultsFound" class="flex justify-between items-center px-8 py-4">
        <p>No results</p>
        <button @click="close" class="section-filter-btn bg-cloud-100 hover:bg-cloud-300 text-steel-500">Close</button>
      </div>
    </div>

  </div>
</template>

<style>
    .results {
        @apply absolute bg-white rounded-b shadow-md mt-3 text-steel-500;
        width: 650px;
    }
    .results a { @apply text-steel-500 }
    .section-group-header { @apply bg-cloud-400 capitalize py-4 px-6 text-sm; }
    .entries-list { @apply cursor-pointer; }
    .entries-list-item { @apply py-4 px-6 border-b-2 border-cloud-100; }
    .entries-list-item:not(:last-child) { @apply border-b-2 border-cloud-100; }
    .entries-list-item:hover { @apply bg-cloud-100; }
    .section-filter-btn { @apply py-3 px-3 mx-px cursor-pointer select-none rounded-lg; }
    .section-filter-btn:hover { @apply bg-cloud-100; }
</style>

<script>
import Highlighter from 'vue-highlight-words'
import {handleError} from '../modules/handleError'
import { debounce } from 'lodash'
export default {
  name: 'ScriptAutocomplete',
  components: { Highlighter },

  data() {
    return {
      results: [],
      noResultsFound: false,
      isSearching: false,
      searchValue: null,
      selected: null,
      sections: {
        'clients': true,
        'drugs': true,
        'orders': false,
        'scripts': true,
        'sites': false,
        'veterinarians': false
      },
    }
  },

  computed: {
    keywords() {
      if (this.searchValue === null) return [];
      return this.searchValue.split(' ');
    }
  },

  methods: {
    getTargetSections() {
      let targetSections = [];
      if (this.sections.clients) targetSections.push('clients');
      if (this.sections.drugs) targetSections.push('drugs');
      if (this.sections.orders) targetSections.push('orders');
      if (this.sections.scripts) targetSections.push('scripts');
      if (this.sections.sites) targetSections.push('sites');
      if (this.sections.veterinarians) targetSections.push('veterinarians');
      return targetSections;
    },

    startSearch() {
      this.noResultsFound = false;
      if (this.searchValue.length < 3) return;
      this.isSearching = true;
      this.search();
    },

    search: debounce( function() {
      let targetSections = this.getTargetSections();
      let data = {
        'searchValue': this.searchValue,
        'targetSections': targetSections
      };
      this.$axios.post('actions/vetscripts/search/autocomplete', data)
        .then((response) => {
          console.log(response);
          this.isSearching = false;
          this.results = response.data.results;
          if (!response.data.results.length) this.noResultsFound = true;
        })
        .catch(handleError)
    }, 500),

    select(id) {
      this.selected = id;
      this.results = null;
    },

    searchSection(section) {
      if (section) {
        return '<i class="fas fa-check-circle text-sm text-mint"></i>';
      } else {
        return '<i class="far fa-circle text-sm"></i>';
      }
    },

    allResultsUrl() {
      return window.Craft.baseUrl + '/search/results?q=' + this.searchValue;
    },
    close() {
      this.results = '';
    },

    clickClientsFilter() {
      this.sections.clients = !this.sections.clients;
      this.results = null;
      this.isSearching = true;
      this.search();
    },
    clickDrugsFilter() {
      this.sections.drugs = !this.sections.drugs;
      this.results = null;
      this.isSearching = true;
      this.search();
    },
    clickOrdersFilter() {
      this.sections.orders = !this.sections.orders;
      this.results = null;
      this.isSearching = true;
      this.search();
    },
    clickScriptsFilter() {
      this.sections.scripts = !this.sections.scripts;
      this.results = null;
      this.isSearching = true;
      this.search();
    },
    clickSitesFilter() {
      this.sections.sites = !this.sections.sites;
      this.results = null;
      this.isSearching = true;
      this.search();
    },
    clickVeterinariansFilter() {
      this.sections.veterinarians = !this.sections.veterinarians;
      this.results = null;
      this.isSearching = true;
      this.search();
    },
  }
}
</script>
