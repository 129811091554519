<template>
    <div :class="size">
        <i class="fas fa-circle-notch fa-spin"></i>
    </div>
</template>

<script>
export default {
  name: 'TheSpinner',

  props: {
    spinnerSize: {
      default: '3x',
      type: String
    }
  },

  computed: {
    size () {
      return 'fa-' + this.spinnerSize;
    }
  }
}
</script>