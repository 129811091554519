<template>
    <button @click="requestPdf" class="font-medium text-steel-600 text-sm uppercase hover:underline cursor-pointer">PDF</button>
</template>

<script>
export default {
  name: 'PdfButton',

  props: ['scriptId'],

  data () {
    return {
      pdfUrl: null,
      success: null,
    }
  },

  methods: {
    requestPdf () {
      let data = {
        scriptId: this.scriptId
      };
      this.$axios.post('actions/vetscripts/scripts/pdf', data, {
        // responseType: 'arrayBuffer',
        // headers: {
        //   'Content-Type': 'application/json',
        //   'Accept': 'application/pdf'
        // }
      })
          .then(response => {
            console.log(response);
            this.success = response.data.success;
            this.pdfUrl = response.data.assetUrl;
            if (this.pdfUrl) {
              let url = Craft.baseUrl + '/' + this.pdfUrl;
              window.open(url, '_blank').focus();
            }
          })
          .catch(error => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
            console.log(error.config);
          })
    }
  }
}
</script>