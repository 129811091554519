<template>
  <div>
    <div class="flex justify-between">
      <h1 class="inline-block text-4xl text-steel-600">
        New Annual General Script
      </h1>
      <button @click="saveEntry()" class="button bg-mint text-mint-light" :disabled="isSaving">
        Save Script
      </button>
    </div>

    <div v-if="isSaving" class="flex bg-white p-8 mt-4 rounded shadow">
      <the-spinner></the-spinner>
    </div>

    <!-- Continue along if we aren't actively trying to save something... -->
    <div v-else class="bg-white p-8 mt-4 rounded shadow">

      <div v-if="orderUrl" class="bg-steel-400 border-t-4 border-steel-600 rounded-b mb-12 mt-2 px-6 py-6 shadow" role="alert">
        <div class="flex">
          <div class="py-1 pr-6">
            <i class="fas fa-thumbs-up text-5xl text-mint-light"></i>
          </div>
          <div>
            <p class="font-bold pb-1 text-lg text-white">Script saved successfully!</p>
            <p class="text-steel-100">Redirecting you to your new Script.</p>
            <p class="text-steel-100">If you haven't been redirected after a few seconds, <a :href="scriptUrl" class="text-white underline">click here</a> to go to your Script.</p>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-full md:w-auto">
          <!-- Veterinarian -->
          <div class="mb-4">
            <label class="block text-sm uppercase font-medium" for="newVeterinarianInput">Veterinarian</label>
            <entry-field
              input-id="newVeterinarianInput"
              section-handle="veterinarians"
              placeholder="Veterinarian Name"
              @relatedEntrySelected="veterinarianSelected"
            ></entry-field>
          </div>

          <!-- Client -->
          <div class="mb-4">
            <label class="block text-sm uppercase font-medium" for="newClientInput">Client</label>
            <entry-field
              input-id="newClientInput"
              section-handle="clients"
              placeholder="Client Name"
              @relatedEntrySelected="clientSelected"
            ></entry-field>
          </div>

          <!-- Client Site -->
          <div v-if="fields.client.length">
            <label class="block text-sm uppercase font-medium" for="newClientSiteInput">Client Site</label>
            <!-- This needs to receive a new prop -->
            <!--   something that helps it search for ClientSites that are -->
            <!--   only related to a received `relatedTo` prop -->
            <!--   e.g.: `:relatedTo="clientId"` -->
            <entry-field
              input-id="newClientSiteInput"
              section-handle="clientSites"
              placeholder="Client Site Name"
              relatedToSection="clients"
              :relatedToEntryId="fields.client"
              @relatedEntrySelected="clientSiteSelected"
            ></entry-field>
          </div>
        </div>
        <div class="w-full md:w-2/3 md:ml-12">
          <label class="block text-sm uppercase font-medium" for="notes">Notes</label>
          <textarea v-model="fields.comments" class="w-full bg-cloud-100 p-2 rounded-lg text-steel-400 text-3xl border-2 border-cloud-100 transition"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  @apply inline-block text-base mr-4 select-none
}
</style>

<script>
import {handleError} from '../../modules/handleError';
import TheSpinner from '../TheSpinner';
import EntryField from '../EntryField';
export default {
  name: 'NewScriptAgs',
  data() {
    return {
      isSaving: false,
      fields: {
        client: [],
        clientSite: [],
        veterinarian: [],
        comments: ''
      },
      orderUrl: ''
    }
  },
  methods: {
    saveEntry() {
      // debugger;
      this.isSaving = true;
      let data = {
        title: this.title,
        fields: this.fields,
      };
      this.$axios.post('actions/vetscripts/scripts/save-ags', data)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.afterSaveCleanup();
            this.scriptUrl = response.data.url;
            window.location.href = this.scriptUrl;
          }
          if (response.data.errors) {
            console.log(errors);
          }
        })
        .catch(handleError);
    },
    afterSaveCleanup() {
      this.isSaving = false;
      this.fields.client = [];
      this.fields.clientSite = [];
      this.fields.veterinarian = [];
    },
    newDrugSelected(id) {
      this.fields.scriptFormula.new1.fields.drug = [id];
      this.fields.scriptFormula.new1.fields.group = id;
    },
    clientSelected(id) {
      this.fields.client = [id];
    },
    clientSiteSelected(id) {
      this.fields.clientSite = [id];
    },
    veterinarianSelected(id) {
      this.fields.veterinarian = [id];
    }
  }
}
</script>
