import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import scripts from './scripts';
import orders from './orders';

export default new Vuex.Store({
  modules: {
    scripts,
    orders
  }
});
