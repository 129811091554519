<template>
  <div>
    <div class="flex justify-between">
      <h1 class="inline-block text-4xl text-steel-600">
        New Site
      </h1>
      <button @click="saveEntry()" class="button bg-mint text-mint-light" :disabled="isSaving">
        Save Site
      </button>
    </div>

    <div v-if="isSaving" class="flex bg-white p-8 mt-4 rounded shadow">
      <the-spinner></the-spinner>
    </div>

    <div v-else class="flex bg-white p-8 mt-4 rounded shadow">
      <div class="w-1/3">
        <label for="title">Site Name</label>
        <input
          v-model="title"
          ref="title"
          type="text"
          id="title"
          placeholder="Site name"
        >
      </div>
      <div class="w-1/3">
        <label for="premiseId">Premise ID</label>
        <input
          v-model="fields.premiseId"
          ref="premiseId"
          type="text"
          id="premiseId"
          placeholder="Premise ID"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  @apply inline-block text-base w-24
}
</style>

<script>
import {handleError} from '../../../modules/handleError';
import TheSpinner from '../../TheSpinner';
export default {
  name: 'NewSite',
  data() {
    return {
      isSaving: false,
      title: '',
      fields: {
        premiseId: ''
      }
    }
  },
  methods: {
    saveEntry() {
      this.isSaving = true;
      let data = {
        title: this.title,
        fields: this.fields
      };
      this.$axios.post('actions/vetscripts/sites/save', data)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.afterSaveCleanup();
            window.location.href = response.data.siteUrl;
          }
          if (response.data.errors) {
            console.log(errors);
          }
        })
        .catch(handleError);
    },
    afterSaveCleanup() {
      this.title = '';
      this.fields.premiseId = '';
      this.isSaving = false;
    }
  }
}
</script>
