<template>
    <div class="flex justify-between">
        <!-- List of Sites -->
        <div>
            <div class="control">
                <label v-for="(site, index) in sites" :key="index" class="radio mr-4">
                    <input :id="site.slug" :value="site.title" type="radio" name="site" @input="selectSite(site)">
                    {{ site.title }}
                </label>
                <label class="radio mr-4">
                    <input id="none" value="" type="radio" name="site" @input="selectSite(null)">
                    No Site
                </label>
            </div>
        </div>

        <!-- Print Link -->
        <a :href="this.url(scriptId, selectedSite)" target="_blank" class="font-medium text-steel-600 text-sm uppercase hover:underline cursor-pointer">Print</a>
    </div>
</template>

<script>
export default {
  name: 'ScriptLabelSitePicker',
  data() {
    return {
      selectedSite: '',
    }
  },
  props: {
    baseUrl: String,
    scriptId: Number,
  },
  computed: {
    sites() {
      return this.$store.getters.SCRIPT_SITES;
    }
  },
  methods: {
    selectSite(site) {
      if (site) {
        this.selectedSite = site.title;
      }
      this.$store.commit('SET_SELECTED_LABEL_SITE', site);
    },
    url (scriptId, selectedSite) {
      //  return baseUrl + 'prints/label?scriptId=' + scriptId + '&site=' + selectedSite;
      //  return window.Craft.baseUrl + '/prints/label?scriptId=' + scriptId + '&site=' + selectedSite;
      return `${window.Craft.baseUrl}/prints/label?scriptId=${scriptId}&site=${selectedSite}`;
    }
  }

}
</script>
