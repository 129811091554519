<template>
    <tr class="new-product-row">
      <td>
        <entry-field input-id="newDrugInput" section-handle="drugs" @relatedEntrySelected="newDrugSelected"></entry-field>
      </td>
      <td colspan="2">
        <input v-model="fields.scriptFormula.new1.fields.dosage" placeholder="Dosage" class="mr-3">
        <select v-model="fields.scriptFormula.new1.fields.uom">
          <option selected value="CC">CC</option>
          <option value="Gallon(s)">Gallon(s)</option>
        </select>
      </td>
      <td colspan="2" class="text-right">
        <button @click="itemSubmitted" :disabled="hasSubmittedNewItem" class="button bg-cloud-300 font-bold text-steel-400 text-xs uppercase">
          Add New Product
        </button>
      </td>
    </tr>
</template>

<script>
export default {
  name: 'ScriptFormulaForm',

  props: {
    hasSubmittedNewItem: Boolean,
    newItemSaved: Boolean,
    scriptId: Number
  },

  data () {
    return {
      entryId: this.scriptId,
      sectionId: 3,
      enabled: 1,
      fields: {
        scriptFormula: {
          new1: {
            type: 'formulaItem',
            enabled: 1,
            sortOrder: null,
            fields: {
              drug: null,
              dosage: null,
              uom: 'CC',
              replaces: null,
              replacedBy: null,
              outOfStock: 0,
              group: null
            }
          }
        }  
      },
    }
  },

  watch: {
    newItemSaved: function(newVal) {
      if (newVal === true) {
        this.fields.scriptFormula.new1.fields.drug = null;
        this.fields.scriptFormula.new1.fields.dosage = null;
        this.fields.scriptFormula.new1.fields.unit = null;
      }
    }
  },

  computed: {
    formula()   { return this.$store.getters.FORMULA; }
  },

  methods: {
    newDrugSelected(id) {
      this.fields.scriptFormula.new1.fields.drug = [id];
      this.fields.scriptFormula.new1.fields.group = id;
    },

    addAllFormulaBlocksToFormulaSubmission() {
      for (let i = 0; i < this.formula.length; i++) {
          this.$set(
              this.fields.scriptFormula,
              this.formula[i].id,
              {
                type: 'formulaItem',
                enabled: 1,
              }
          );
        }
    },   

    itemSubmitted () {
      // make sure we're sending all pre-existing Lots along with our submission
      this.addAllFormulaBlocksToFormulaSubmission();
      // what we're sending
      let data = {
        entryId: this.entryId,
        sectionId: this.sectionId,
        enabled: this.enabled,
        fields: this.fields
      };
      // bird's away!
      this.$emit('itemSubmitted', data);
    }
  }
}
</script>