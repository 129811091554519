export default {
  state: {
    scriptId: Number,
    isEditable: Number,
    script: null,
    scriptSites: null,
    formula: null,
    scriptLots: [],
    scriptRecentLots: null,
    cpEditUrl: null,
    replacementDrugs: null,
    selectedLabelSite: null
  },

  getters: {
    SCRIPT: state => { return state.script },
    SCRIPT_ID: state => { return state.scriptId },
    SCRIPT_SITES: state => { return state.scriptSites },
    CP_EDIT_URL: state => { return state.cpEditUrl },
    LOTS: state => { return state.scriptLots },
    RECENT_LOTS: state => { return state.scriptRecentLots },
    FORMULA: state => { return state.formula },
    REPLACEMENT_DRUGS: state => { return state.replacementDrugs },
    IS_EDITABLE: state => { return state.isEditable },
    SELECTED_LABEL_SITE: state => { return state.selectedLabelSite },
  },

  mutations: {
    SET_SCRIPT_ID (state, id) {
      state.scriptId = id
    },
    SET_SCRIPT_SITES (state, sites) {
      state.scriptSites = sites
    },
    SET_IS_EDITABLE (state, editable) {
      state.isEditable = editable
    },
    SET_SCRIPT (state, script) {
      state.script = script
    },
    SET_FORMULA (state, formula) {
      state.formula = formula
    },
    SET_REPLACEMENT_DRUGS (state, replacementDrugs) {
      state.replacementDrugs = replacementDrugs
    },
    SET_SCRIPT_LOTS (state, lots) {
      state.scriptLots = lots
    },
    SET_SCRIPT_RECENT_LOTS (state, recentLots) {
      state.scriptRecentLots = recentLots
    },
    SET_CP_EDIT_URL (state, url) {
      state.cpEditUrl = url
    },
    SET_SELECTED_LABEL_SITE (state, site) {
      state.selectedLabelSite = site
    }
  },
}
