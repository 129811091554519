<template>
  <div v-if="script" style="width:41%">
    <div class="px-4 text-sm">
      <div class="bg-white flex-grow leading-tight rounded shadow">
        <div class="flex flex-col justify-between">
          <!-- label -->
          <div class="flex-grow p-8" :class="printLabelColorClass(script.labelColor)">
            <!-- vet clinic info -->
            <div class="flex w-full mb-2">
              <div class="w-1/2">
                <p><strong>FAIRMONT VETERINARY CLINIC, LLP</strong></p>
                <p>1275 Hwy 15 S, Fairmont MN 56031</p>
              </div>
              <div class="w-1/2 text-right">
                <p>Phone: 507-238-4456</p>
                <p>Toll Free: 1-800-335-0126</p>
              </div>
            </div>
            <!-- client, rx, and diagnosis -->
            <div class="text-center">
              <p class="font-bold text-lg">
                <!-- client -->
                <span v-if="script.client">
                  {{ script.client.title }}
                </span>
                <span v-else>NO CLIENT DATA</span>
                <!-- lot site -->
                <span v-if="(lots != undefined && !lots.length) && selectedLabelSite">&ndash; {{ selectedLabelSite.title }}</span>
                <span v-if="lastLot && lastLot.lotSite && lastLot.lotSite.length > 0">&ndash; {{ lastLot.lotSite[0].title }}</span>
                <!-- rx -->
                &ndash; &rx; {{ script.title }}
              </p>
              <!-- diagnosis -->
              <p class="font-bold text-lg">
                <strong>
                    <!-- special oral drug -->
                    <span v-if="script.specialOralDrug">
                      {{ printFirstDrug() }} &ndash; {{ script.diagnosis }}
                    </span>
                    <span v-else>
                      {{ script.diagnosis }}
                    </span>
                </strong>
              </p>
            </div>

            <hr>

            <!-- Instructions -->
            <p>
              <u>Dosage Instructions:</u>
              <span v-if="script.specialOralDrug">
                {{ formula[0].drug.specialInstructions }}
              </span>
              <span
                v-if="formula.length > 0 && !script.specialOralDrug"
                class="font-bold text-lg text-gray-800"
                :class="totalDosageHighlightClass()"
              >
                {{ totalDosage() }} DOSE PER HEAD.
              </span>
              {{ script.administrativeRoute }}. {{ script.slaughterWithdrawal }} day slaughter withdrawal.
              <!-- label options -->
              <span v-for="(option, index) in script.labelOptions" :key="index">{{ option.label }}. </span>
            </p>
            <p v-if="script.extraDosageInstructions">
              <u>Extra Instructions:</u>
              {{ script.extraDosageInstructions }}
            </p>

            <!-- Show contents of formula -->
            <!-- only if this is not a special oral drug -->
            <div v-if="!script.specialOralDrug">
              <div v-if="formula.length > 0">
                <u>Contains:</u>
                <span v-for="(item, index) in productsNotOutOfStock" :key="index" class="comma-separated">
                    <!-- @todo refactor product formula so we can easily grab drugs and replacements -->
                    {{ item.drug.title }}
                </span>
              </div>
              <div v-else>
                <ul>Contains: N/A</ul>
              </div>
            </div>

            <p>KEEP OUT OF REACH OF CHILDREN.</p>

            <!-- Vet, Dates, and Lot Info -->
            <p>
              <u v-if="script.veterinarian.length">{{ script.veterinarian[0].title }}. </u>
              <!-- last lot item if NOT special oral drug -->
              <u v-if="!script.specialOralDrug && lastLot && lastLot.lotMixDate">
                Date: {{ lastLot.lotMixDate.date|formatDate }}.
                Lot #: {{ lastLot.lotNumber }}.
                Expires: {{ lastLot.lotExpireDate.date|formatDate }}.
              </u>
              <u v-else>NO SCRIPT LOT DATA.</u>
            </p>
          </div>
          <!-- Interaction -->
          <div class="flex justify-between p-4 border-t-2 border-cloud-100">
            <!-- Site Picker -->
            <div v-if="(lots != undefined && !lots.length) && script.sites.length" class="control">
              <label v-for="(site, index) in script.sites" :key="index" class="radio mr-4">
                <input :id="site.id" :value="site.title" type="radio" name="site" @input="selectSite(site)">
                {{ site.title }}
              </label>
              <label class="radio mr-4">
                <input id="none" value="" type="radio" name="site" @input="selectSite(null)">
                No Site
              </label>
            </div>
            <!-- this is a stupid empty div in case there are lots so that the print button below stays to the right. -->
            <div v-if="lots"></div>
            <div>
              <a v-if="!lots" :href="printLabelUrlWithSite()" target="_blank" class="font-medium text-steel-600 text-sm uppercase hover:underline cursor-pointer">Print</a>
              <a v-else :href="printLabelUrl()" target="_blank" class="font-medium text-steel-600 text-sm uppercase hover:underline cursor-pointer">Print</a>
            </div>
          </div> <!-- / interaction -->
        </div>
      </div>
    </div>
  </div>
  <div v-else style="width:41%">
    <div class="px-4 text-sm">
      <div class="bg-white flex-grow leading-tight p-8 rounded shadow">
        <the-spinner></the-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScriptLabel',

  computed: {
    script() { return this.$store.getters.SCRIPT; },
    formula() { return this.$store.getters.FORMULA; },
    lots() { return this.$store.getters.LOTS; },
    lastLot() {
      // return this.lots[this.lots.length - 1];
      // I guess the most recent lot is the 0 position now!
      if (!this.lots) return {};
      return this.lots[0];
    },
    selectedLabelSite() { return this.$store.getters.SELECTED_LABEL_SITE; },
    replacementDrugs() { return this.$store.getters.REPLACEMENT_DRUGS; },
    productsNotOutOfStock() {
      let array = [];
      for (let i = 0; i < this.formula.length; i++) {
        if (!this.formula[i].outOfStock) {
          array.push(this.formula[i]);
        }
      }
      return array;
    },
    baseProducts() {
      if (this.formula && this.formula.length > 0) {
        let baseProducts = [];
        for (let i = 0; i < this.formula.length; i++) {
          if (!(this.formula[i].replaces)) {
            baseProducts.push(this.formula[i]);
          }
        }
        return baseProducts;
      }
    },
    replacementProducts() {
      if (this.formula && this.formula.length > 0) {
        let replacementProducts = [];
        for (let i = 0; i < this.formula.length; i++) {
          if (this.formula[i].replaces) {
            replacementProducts.push(this.formula[i]);
          }
        }
        return replacementProducts;
      }
    },
  },

  methods: {
    printLabelColorClass(labelColor) {
      return 'print_label--bg-' + labelColor;
    },
    // printLotSite() {
    //   if (!this.lots) return;
    //   if (this.lots.length === 0) return;
    //   if (this.lots[0].lotSite.length) {
    //     return '– ' + this.lots[0].lotSite[0].title;
    //   }
    // },
    printFirstDrug() {
      if (this.formula.length) {
        return this.formula[0].drug.title;
      }
    },
    totalDosage() {
      if ( (this.baseProducts && this.baseProducts.length > 0) ) {
        let totalDosage = 0;
        for (let i = 0; i < this.baseProducts.length; i++) {
          for (let r = 0; r < this.replacementProducts.length; r++) {
            if (this.replacementProducts[r].group !== this.baseProducts[i].group) continue;
            if (!this.baseProducts[i].outOfStock) continue;
            totalDosage += this.replacementProducts[r].dosage * 100;
          }
          if (this.baseProducts[i].outOfStock) continue;
          totalDosage += this.baseProducts[i].dosage * 100;
        }
        const total = totalDosage / 100;
        return `${total.toString()} ${this.formula[0].uom}`;
      }
    },
    totalDosageHighlightClass() {
      if (this.script.labelColor.value !== 'noColor' || this.script.labelColor === null) {
        return;
      } else {
        return 'has-text-danger';
      }
    },
    selectSite(site) {
      if (site) {
        this.selectedSite = site.title;
      }
      this.$store.commit('SET_SELECTED_LABEL_SITE', site);
    },
    printLabelUrl(scriptId) {
      const baseLabelUrl = `${window.Craft.baseUrl}/prints/label?scriptId=${this.script.id}&dosage=${this.totalDosage()}`
      if (this.selectedLabelSite) {
        return `${baseLabelUrl}&site=${this.selectedLabelSite.title}`;
      }
    },
    printLabelUrl(scriptId) {
      const baseLabelUrl = `${window.Craft.baseUrl}/prints/label?scriptId=${this.script.id}&dosage=${this.totalDosage()}`
      if (this.selectedLabelSite) {
        return `${baseLabelUrl}&site=${this.selectedLabelSite.title}`;
      }
      return baseLabelUrl;
    }
  }
}
</script>
