// Get the App version number from package.json

let getAppVersion = function () {
  if (window.Craft === null) return;
  let pkg = require('../../package');
  window.Craft.appVersion = pkg.version;
  let appVersionSpan = document.getElementById('appVersion');

  appVersionSpan.innerText = window.Craft.appVersion;
};

export { getAppVersion }